<template>
  <div class="py-5 bg-c7" id="contact">
    <div class="container-fluid py-5" data-aos="fade-down" data-aos-duration="1000">

      <div class="row pb-5">
        <div class="col-6 mx-auto text-center">
          <p class="display-1 mb-1 text-white"><i class="fal fa-envelope"></i></p>
          <h1 class="text-white mb-4"><strong>Contacto</strong></h1>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mx-auto">
          <div class="form-group text-center">
            <b-form-input v-model="form.name" class="mb-4" block placeholder="Nombre"></b-form-input>
            <b-form-input v-model="form.phone" class="mb-4" block placeholder="Teléfono Celular"></b-form-input>
            <b-form-input v-model="form.email" class="mb-4" block placeholder="Correo electrónico"></b-form-input>
            <b-form-textarea v-model="form.commentary" class="mb-4" block placeholder="Ingresa un comentario"></b-form-textarea>
            <b-button v-on:click="send_email()" class="col-6" variant="c3">Enviar</b-button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      form: '',
      sended: ''
    }
  },
  methods: {
    async send_email() {
      axios.post('https://aprolider.com/api/send_email', this.form).then( (r) => {
        this.sended = r.data.data
      })
    }
  },
  mounted() {
    this.form = {
      name: '',
      phone: '',
      email: '',
      commentary: ''
    }
  }
}
</script>